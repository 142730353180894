import { Classification } from 'dto/classification';
import {
  Condition,
  Journey as TripJourney,
  Price,
  Trip,
  TripOffer,
  TripStop,
} from 'dto/trip';

export interface Warnings {
  warnings: Array<{
    code: string;
    type: string;
    detail: string;
    instance: string;
  }>;
}

export interface Fee {
  amount: number;
  currency: string;
  vats: Array<{
    amount: number;
    percentage: number;
  }>;
}

export interface BookingDetails {
  warnings: Warnings;
  id: string;
  externalReference: string;
  createdOn: string;
  status: string;
  provisionalPrice: Price;
  confirmableUntil: string;
  confirmedPrice: Price;
  bookingParts: Array<BookingPart | TravelPassBookingPart>;
  bookedTrips: Array<BookingTripWithAdmissions>;
  purchaser: BookingDetailsPurchaser;
  passengers: Array<BookingDetailsPassenger>;
  possibleFulfillmentOptions: Array<{
    type: string;
    media: string;
  }>;
  code: string;
  possiblePaymentMethods: Array<{
    type: string;
    voucher: {
      issuer: string;
      code: string;
    };
    remainingPrice: Price;
  }>;
  soldByOrganization: string | null;
}

export interface BookingPassengerCard {
  name: string;
  code?: string;
  number: string;
}

export enum FulfillmentStatus {
  AVAILABLE = 'AVAILABLE',
  PREBOOKED = 'PREBOOKED',
  FULFILLED = 'FULFILLED',
  REFUNDED = 'REFUNDED',
  RELEASED = 'RELEASED',
  ON_HOLD = 'ON_HOLD',
}
export interface FulfillmentDocument {
  mediumType: string;
  documentType: string;
  documentFormat?: string;
  documentContent?: string;
  downloadLink?: string;
  downloadExpiry: string;
}

export interface BookingFulfillment {
  id: string;
  status: FulfillmentStatus;
  mediumType: string;
  controlNumber: string;
  documentFormat: string;
  documentType: string;
  downloadLink: string;
  issuer?: string;
  fulfillmentDocuments: Array<FulfillmentDocument>;
}

export interface BookingPart {
  journey: TripJourney;
  fee: {
    productDescription: string;
    price: Price;
    conditions: [
      {
        type: string;
        description: string;
        fee: Price;
      }
    ];
    fulfillments: Array<BookingFulfillment>;
  };
}

export interface TravelPassBookingPart {
  nonTripOffer: {
    conditions: Array<Condition>;
    productDescription: string;
    productCode: string;
    productType: 'ADMISSION_PASS' | 'ADMISSION_MULTI_RIDE' | null;
    travelClass: string;
    serviceClass: {
      code: string;
      name: string;
    };
    fees: Array<PassengerFee>;
    refundable: string;
    exchangeable: string;
    price: Price;
    productDescriptiveTexts: Array<string>;
    passengerIds: Array<string>;
    fulfillments: Array<BookingFulfillment>;
    validFrom: string;
    validUntil: string;
  };
}

export interface TravelPassConsumption {
  consumedOn: string;
  bookingId: string;
  unit: {
    objectType: string;
  };
  bookingSummary: string;
  tripSummary: {
    id: string;
    summary: string;
    externalRef: string;
    startTime: string;
    endTime: string;
    duration: string;
    origin: {
      stopPlaceRef: string;
      objectType: string;
    };
    destination: {
      stopPlaceRef: string;
      objectType: string;
    };
    transfers: number;
    distance: number;
  };
  consumedAmount: number;
}
export interface TravelAccount {
  balance: {
    total: number;
    remaining: number;
    unit: {
      objectType: string;
    };
  };
  consumptions: Array<TravelPassConsumption>;
  validFrom: string;
  validUntil: string;
}

export type PassengerFee = {
  id: string;
  description: string;
  price: Price;
  fulfillments: Array<BookingFulfillment>;
};

export interface BookedOffer {
  id: string;
  createdOn: string;
  admissions: Array<BookingAdmission>;
  fees: Array<PassengerFee>;
}

export interface BookingTripWithAdmissions extends Omit<Trip, 'offers'> {
  journeyRef: string;
  alliances: Array<string>;
  isOutbound: boolean;
  bookedOffers: Array<BookedOffer>;
}

type AdmissionTextElementType = 'INFORMATION';
export interface BookingAdmission
  extends Omit<
    TripOffer,
    | 'productDescription'
    | 'conditions'
    | 'appliedReductions'
    | 'offerMode'
    | 'passengers'
  > {
  confirmableUntil: string;
  description: string;
  summary: string;
  status: FulfillmentStatus;
  reservations: Array<BookingReservation>;
  ancillaries: Array<BookingAdmissionAncillary>;
  feeRefs: Array<string>;
  fees: Array<PassengerFee>;
  fulfillments: Array<BookingFulfillment>;
  passengerTypes: Array<string>;
  appliedPassengerTypes: Array<{
    type: string;
    description: string;
    passengerRef: string;
  }>;
  passengerIds: Array<string>;
  productDescriptiveTexts: Array<{
    description: string;
    shortDescription: string;
    textElementType: AdmissionTextElementType;
  }>;
  appliedReductions: Array<{
    type: 'CARD' | 'PROMO' | 'CORPORATE';
    passengerRef?: string;
    number?: string;
    name: string;
    code: string;
  }>;
}

export interface PlaceAllocation {
  legId: string;
  accomodationType: string;
  accommodationSubType: Array<string>;
  reservedPlaces: Array<{
    coachNumber: string;
    placeNumber: string;
    placeDescription: string;
    placeProperties: Array<SeatProperty>;
  }>;
}

export interface BookingReservation {
  id: string;
  status: string;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  price: Price;
  fulfillments: Array<BookingFulfillment>;
  placeAllocations: PlaceAllocation;
}
export interface BookingRefundOffer {
  id: string;
  summary: string;
  status: string;
  fulfillmentId: string;
  refundFee: Fee;
  refundableAmount: Fee;
  createdOn: string;
  validUntil: string;
  confirmedOn: string;
  appliedOverruleCode: string;
}

export interface BookingReleaseOffer {
  id: string;
  fulfillmentId: string;
  summary: string;
  status: string;
  createdOn: string;
  validUntil: string;
  confirmedOn: string;
}

export interface BookingAdmissionAncillary {
  id: string;
  bookedOfferId: string;
  type: string;
  status: FulfillmentStatus;
  productDescription: string;
  fulfillments: Array<BookingFulfillment>;
  productSummary: string;
  amountOf: number;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  price: Price;
}

export interface BookingDetailsPurchaser {
  externalReference: string;
  firstName: {
    isRequired: boolean;
    value: string;
  };
  lastName: {
    isRequired: boolean;
    value: string;
  };
  companyName: {
    isRequired: boolean;
    value: string;
  };
  companyRegistrationNumber: {
    isRequired: boolean;
    value: string;
  };
  vatNumber: {
    isRequired: boolean;
    value: string;
  };
  contactInformation: {
    isRequired: true;
    emailAddress: {
      isRequired: boolean;
      value: string;
    };
    phoneNumber: {
      isRequired: boolean;
      value: string;
    };
  };
  address: {
    isRequired: boolean;
    streetName: string;
    zipCode: string;
    city: string;
    countryName: string;
  };
}

export enum PRM_NEEDS {
  EASY_ACCESS = 'EASY_ACCESS',
  WHEELCHAIR_AND_SEAT = 'WHEELCHAIR_AND_SEAT',
  NEED_PRM_SUPPORT = 'NEED_PRM_SUPPORT',
  WHEELCHAIR = 'WHEELCHAIR',
  ACCOMPANYING_DOG = 'ACCOMPANYING_DOG',
}

export interface BookingDetailsPassenger {
  id: string;
  externalReference: string;
  age: number;
  firstName: {
    isRequired: true;
    value: string;
  };
  lastName: {
    isRequired: true;
    value: string;
  };
  gender: {
    isRequired: false;
    value: string;
  };
  prmNeeds: {
    isRequired: false;
    value: Array<PRM_NEEDS>;
  };
  contactInformation: {
    isRequired: true;
    emailAddress: {
      isRequired: boolean;
      value: string;
    };
    phoneNumber: {
      isRequired: boolean;
      value: string;
    };
  };
  address: {
    isRequired: boolean;
    streetName: string;
    zipCode: string;
    city: string;
    countryCode: string;
  };
  birthDate: string;
  isCardsRequired: boolean;
  cards: Array<BookingPassengerCard>;
}

export interface BookingRefundOffer {
  refundCalculationId: string;
  passengers: Array<{
    id: string;
    firstName: string;
    lastName: string;
    refundOffers: Array<{
      id: string;
      leg: {
        isRefundable: boolean;
        isChangeable: boolean;
        origin: {
          departureDate: Date;
          stopName: string;
        };
        destination: {
          arrivalDate: Date;
          stopName: string;
        };
        price: { amount: number; currency: string };
      };
      refundFee: { amount: number; currency: string };
      refundableAmount: { amount: number; currency: string };
      validUntil: string;
    }>;
  }>;
}

interface Duration {
  ticks: number;
  days: number;
  hours: number;
  milliseconds: number;
  minutes: number;
  seconds: number;
  totalDays: number;
  totalHours: number;
  totalMilliseconds: number;
  totalMinutes: number;
  totalSeconds: number;
}

export type Journey = {
  [index in 'origin' | 'destination']: {
    arrivalDate?: string;
    departureDate?: string;
    stopName: string;
  };
};

interface Passenger {
  id: string;
  firstName: string;
  lastName: string;
  places: [
    {
      placeClass: string | null;
      number: number | null;
      vehicle: string | null;
    }
  ];
}

export interface Leg {
  orderNumber: number;
  carrierName: string;
  duration: Duration;
  originStop: {
    departureDate: Date;
    stopName: string;
  };
  destinationStop: {
    arrivalDate: Date;
    stopName: string;
  };
  passenger: Array<Passenger>;
}

export interface JourneySummary {
  outbound: {
    legs: Array<Leg>;
  };
  inbound: {
    legs: Array<Leg>;
  };
}

export interface BookingTrip {
  id: string;
  status: {
    id: string;
    name: string;
  };
  legs: [
    {
      isRefundable: boolean;
      isChangeable: boolean;
      orderNumber: number;
      carrierName: string;
      duration: Duration;
      originStop: {
        departureDate: Date;
        stopName: string;
      };
      destinationStop: {
        arrivalDate: Date;
        stopName: string;
      };
      places: Array<string | null>;
      ancillaries: Array<string | null>;
      fees: Array<string | null>;
    }
  ];
}

interface Gender {
  id: string;
  name: string;
}

interface Phone {
  areacode: string;
  number: string;
}

interface Passenger {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: {
    year: number;
    month: number;
    day: number;
    dayOfWeek: string;
    dayOfYear: number;
    dayNumber: number;
  };
  type: {
    id: string;
    name: string;
  };
  gender: Gender;
  email: string;
  phone: Phone;
  document: {
    number: string;
    type: {
      id: string;
      name: string;
    };
  };
  nationality: {
    id: string;
    name: string;
  };
}

export enum PassengerType {
  COMPANION_DOG = 'COMPANION_DOG',
  PERSON = 'PERSON',
}

export enum PaymentStatusFilter {
  ALL = '',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  ON_HOLD = 'ON_HOLD',
}
export interface BookingSearchFilter {
  reference: string;
  travelDateRange: {
    startTime: string;
    endDate: string;
  };
  purchaseDateRange: {
    startTime: string;
    endDate: string;
  };
  purchaserPassengerName: string;
  purchaserPassengerEmail: string;
  purchaserPassengerPhone: string;
  cardNumber: string;
  corporateAgreementReference: string;
  origin: string;
  destination: string;
  retailerId: string;
  userName: string;
  pointOfSalesType: string;
  service: string;
  paymentStatus: PaymentStatusFilter;
  pagination: {
    offset: number;
    limit: number;
  };
}

export interface BookingSearchFilterForm
  extends Omit<BookingSearchFilter, 'travelDateRange' | 'purchaseDateRange'> {
  travelDateRange: {
    from: string;
    to: string;
  };
  purchaseDateRange: {
    from: string;
    to: string;
  };
}

export interface BookingsSearchResult {
  id: string;
  code: string;
  hasMultipleJourneys: boolean;
  createdOn: string;
  status: BookingPartStatus;
  journeyOrigin: {
    name: string;
    time: string;
  };
  journeyDestination: {
    name: string;
    time: string;
  };
  passengers: Array<{
    extRef: string;
    firstName: string;
    id: string;
    lastName: string;
    prmNeeds: Array<string>;
    type: string;
  }>;
  totalPrice: {
    amount: number;
    currency: string;
  };
  validUntil?: string;
}

export interface BookingComment {
  id: string;
  bookingId: string;
  type: Classification;
  content: string;
  isDeleted: boolean;
  createInfo: {
    userId: string;
    userName: string;
    createdOn: string;
    lastChangedOn: string;
  };
}

export interface BookingAdditionalOffer {
  offerId: string;
  ancillaryOffers: Array<BookingAncillaryOffer>;
  reservationOffers: Array<BookingReservationOffer>;
}

export interface BookingAncillaryOffer {
  ancillaryOfferId: string;
  type: string;
  productDescription: string;
  price: Price;
  tripCoverage: {
    tripId: string;
    journeyReference: string;
    coveredLegIds: Array<string>;
  };
  travelClass: string;
  serviceClass: {
    code: string;
    name: string;
  };
  afterSalesConditions: Array<{
    type: string;
    fee: Fee;
  }>;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
}

export interface BookingReservationOffer {
  reservationOfferId: string;
  productDescription: string;
  price: Price;
  tripCoverage: {
    tripId: string;
    journeyReference: string;
    coveredLegIds: Array<string>;
  };
  travelClass: string;
  serviceClass: {
    code: string;
    name: string;
  };
  afterSalesConditions: Array<{
    type: string;
    fee: Fee;
  }>;
  appliedReductions: Array<{
    type: string;
    code: string;
  }>;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
}

export interface HistoryEvent {
  id: string;
  timestamp: string;
  change: FulfillmentStatus;
  affectedOfferIds: Array<string>;
  affectedPersons: Array<string>;
  summary: string;
  requestor: string;
  user: string;
  changeReason: RefundReason;
  paymentMethod: string;
  amount: string;
  resource: {
    title: string;
    href: string;
  };
}

export enum RefundReason {
  CLIENT_WISH = 'CLIENT_WISH',
  DELAY_COMPENSATION = 'DELAY_COMPENSATION',
  EXTERNAL_COMPENSATION = 'EXTERNAL_COMPENSATION',
  DISRUPTION = 'DISRUPTION',
  MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
  SALES_STAFF_ERROR = 'SALES_STAFF_ERROR',
  JOURNEY_OBSOLETE = 'JOURNEY_OBSOLETE',
  TECHNICAL_FAILURE = 'TECHNICAL_FAILURE',
}

export type SeatProperty =
  | 'WITH_ANIMALS'
  | 'USB'
  | 'TABLE'
  | 'SILENCE'
  | 'POWER'
  | 'NEAR_WHEELCHAIR'
  | 'NEAR_WHEELCHAIR_AREA'
  | 'NEAR_BICYCLE_AREA'
  | 'NEAR_ANIMALS'
  | 'MIDDLE_SEAT'
  | 'EASY_ACCESS'
  | 'WINDOW'
  | 'AISLE'
  | 'BLOCKED'
  | 'UNACCOMPANIED_MINOR';

export interface AdditionalOffer {
  additionalOfferId: string;
  bookedOfferId: string;
  ancillaryOfferParts: Array<AncillaryOfferPartsItem>;
  reservationOfferParts: Array<AncillaryOfferPartsItem>;
  admissionOfferParts: Array<AncillaryOfferPartsItem>;
}

export interface AncillaryOfferPartsItem {
  tripId: string;
  legIds: string[];
  departureTime: string;
  departureStopName: string;
  arrivalTime: string;
  arrivalStopName: string;
  id: string;
  type: string;
  passengerRefs: string[];
  price: Price;
  travelClass: string;
  serviceClass: {
    code: string;
    name: string;
  };
  products: Array<{
    description: string;
    descriptiveTexts: Array<string>;
  }>;
  afterSalesConditions: Array<{
    type: string;
    fee: Fee;
    descriptiveText: string;
  }>;
  appliedReductions: Array<{
    type: string;
    code: string;
  }>;
  appliedPromotionCodes: Array<{
    code: string;
    issuingCarrierCode: string;
  }>;
  appliedCorporateCodes: Array<{
    code: string;
    issuingCarrierCode: string;
    beneficiary: string;
  }>;
  refundable: string;
  exchangeable: string;
}

export interface AvailabilityPreferences {
  warnings: Warnings;
  productName: string;
  tripId: string;
  duration: string;
  departureTime: string;
  arrivalTime: string;
  originStop: TripStop;
  destinationStop: TripStop;
  legs: Array<AvailabilityPreferenceLeg>;
}

interface AvailabilityPreferenceLeg {
  id: string;
  duration: string;
  departureTime: string;
  arrivalTime: string;
  originStop: TripStop;
  destinationStop: TripStop;
  carrier: {
    code: string;
    name: string;
  };
  ptMode: {
    code: string;
    name: string;
  };
  productCategory: {
    code: string;
    name: string;
  };
  serviceCode: string;
  lineNumber: string;
  intermediateStops: Array<{
    code: string;
    name: string;
    countryName: string;
    cityName: string;
    departureTime: string;
    arrivalTime: string;
  }>;
  serviceAttributes: Array<{
    code: string;
    description: string;
  }>;
  legTotalAvailability: number;
  placePropertyAvailability: {
    placeProperty: string;
    availability: number;
  };
}

export interface OnHoldBookingOffer {
  onHoldOffer: {
    id: string;
    summary: string;
    createdOn: string;
    validFrom: string;
    validUntil: string;
    increasedTTL: string;
    onHoldFee: {
      id: string;
      price: {
        currency: string;
        amount: number;
        scale: number;
      };
    };
    deposit: {
      currency: string;
      amount: number;
      scale: number;
    };
  };
}

export enum BookingPartStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  ON_HOLD = 'ON_HOLD',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
  RELEASED = 'RELEASED',
}
